
<template>
  <v-menu
    offset-y
    :close-on-content-click="false"
  >
    <template #activator="{ on }">
      <v-btn
        icon
        small
        class="ml-2 mr-0"
        v-on="on"
      >
        <v-badge
          :value="$auth.impersonating()"
          color="orange"
          offset-x="9"
          offset-y="9"
          bordered
          bottom
          overlap
          dot
        >
          <v-avatar
            size="36"
          >
            <v-img :src="$auth.user().avatar" />
          </v-avatar>
        </v-badge>
      </v-btn>
    </template>
    <v-list
      class="py-0"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            v-text="$auth.user().first_name + ' ' + $auth.user().last_name"
          />
          <v-list-item-subtitle
            v-text="$auth.user().email"
          />
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list
        dense
        class="my-0 py-0"
        width="auto"
      >
        <v-list-item>
          <v-list-item-action
            class="mr-4"
          >
            <v-icon small>
              fal fa-fw fa-language
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <language-switcher
              class="text-caption mb-n4"
              style="max-width: 120px"
              full-name
              flat
              dense
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item
          v-if="$auth.check({ patients: ['phi','phi_toggle'] })"
          @click="hideData('patients', 'phi','phi_toggle')"
        >
          <v-list-item-action
            :key="$auth.check({ patients: 'phi' }) ? 'fa-eye-slash' : 'fa-eye'"
            class="mr-4"
          >
            <v-icon
              small
              v-text="$auth.check({ patients: 'phi' }) ? 'fal fa-fw fa-eye-slash' : 'fal fa-fw fa-eye'"
            />
          </v-list-item-action>
          <v-list-item-title
            v-text="$auth.check({ patients: 'phi' }) ? 'Hide PHI' : 'Show PHI'"
          />
        </v-list-item>
        <v-list-item
          v-if="$auth.check({ financials: ['view','view_toggle'] })"
          @click="hideData('financials', 'view','view_toggle')"
        >
          <v-list-item-action
            :key="$auth.check({ financials: 'view' }) ? 'fa-eye-slash' : 'fa-eye'"
            class="mr-4"
          >
            <v-icon
              small
              v-text="$auth.check({ financials: 'view' }) ? 'fal fa-fw fa-eye-slash' : 'fal fa-fw fa-eye'"
            />
          </v-list-item-action>
          <v-list-item-title
            v-text="$auth.check({ financials: 'view' }) ? 'Hide Financials' : 'Show Financials'"
          />
        </v-list-item>
        <v-divider />
        <template v-if="$auth.impersonating()">
          <v-list-item
            @click="$auth.unimpersonate({
              redirect: { path: '/' },
            })"
          >
            <v-list-item-action class="mr-4">
              <v-icon small>
                fal fa-user-slash
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Unimpersonate</v-list-item-title>
          </v-list-item>
          <v-divider />
        </template>
        <v-dialog
          v-else
          v-model="changePasswordDialog.open"
          max-width="400"
        >
          <template #activator="{ on }">
            <v-list-item
              v-on="on"
              @click="changePasswordDialog.open = true"
            >
              <v-list-item-action class="mr-4">
                <v-icon small>
                  fal fa-fw fa-lock
                </v-icon>
              </v-list-item-action>
              <v-list-item-title>Change Password</v-list-item-title>
            </v-list-item>
          </template>
          <v-card>
            <v-form
              v-model="changePasswordDialog.valid"
              @submit.prevent="changePassword"
            >
              <v-card-title>Change Password</v-card-title>
              <v-divider />
              <v-card-text>
                <v-text-field
                  v-model="changePasswordDialog.form.password_current"
                  label="Current Password"
                  prepend-icon="mdi-lock"
                  type="password"
                  :rules="rules.password_current"
                />
                <v-text-field
                  v-model="changePasswordDialog.form.password"
                  label="New Password"
                  name="password"
                  required
                  counter
                  minlength="8"
                  prepend-icon="mdi-fingerprint"
                  :append-icon="changePasswordDialog.show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="changePasswordDialog.show_password ? 'text' : 'password'"
                  hint="At least 8 characters"
                  :rules="rules.password"
                  @click:append="changePasswordDialog.show_password = !changePasswordDialog.show_password"
                />
                <v-text-field
                  v-model="changePasswordDialog.form.password_confirmation"
                  label="Confirm Password"
                  name="password_confirmation"
                  type="password"
                  required
                  minlength="8"
                  prepend-icon="mdi-fingerprint"
                  :rules="rules.password_confirmation"
                />
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  @click="changePasswordDialog.open = false"
                >
                  {{ $t('Cancel') }}
                </v-btn>
                <v-btn
                  color="primary"
                  type="submit"
                  :loading="changePasswordDialog.loading"
                >
                  {{ $t('Update') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-divider />
        <v-list-item
          @click="$auth.logout()"
        >
          <v-list-item-action class="mr-4">
            <v-icon small>
              fal fa-fw fa-sign-out
            </v-icon>
          </v-list-item-action>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-list>
  </v-menu>
</template>
<script>
// Utilities
  import {
mapMutations,
mapState,
} from 'vuex'

  export default {
    components: {
      LanguageSwitcher: () => import('@/components/selects/LanguageSwitcher.vue'),
    },
    props: {
      opened: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        changePasswordDialog: {
          form: {},
          show_password: false,
          valid: false,
          open: false,
        },
        rules: {
          password_current: [
            v => !!v || 'Please enter your current password',
          ],
          password: [
            v => !!v || 'Please enter your new password',
            v => v?.length >= 8 || 'Password must have a minimum of 8 characters',
          ],
          password_confirmation: [
            v => !!v || 'Please re-enter your new password',
            v => v === this.changePasswordDialog.form.password || 'Passwords must match',
          ],
        },
        responsive: false,
      }
    },
    computed: {
      ...mapState('app', ['image', 'color', 'settings']),
      inputValue: {
        get () {
          return this.$store.state.app.drawer
        },
        set (val) {
          this.setDrawer(val)
        },
      },
      adminMenu: {
        get () {
          return this.$store.state.app.admin_menu
        },
        set (val) {
          this.setAdminMenu(val)
        },
      },
    },
    mounted() {
      if(this.settings.hide_patients){
        let index = this.$auth.user().roles['patients'].indexOf('phi');
        this.replaceRol('patients', 'phi_toggle', index)
      }
      if(this.settings.hide_financials){
        let index = this.$auth.user().roles['financials'].indexOf('view');
        this.replaceRol('financials', 'view_toggle', index)
      }
    },
    methods: {
      ...mapMutations('app', ['setDrawer', 'toggleDrawer', 'setSettings']),
      changePassword () {
        if (this.changePasswordDialog.valid) {
          this.changePasswordDialog.loading = true
          let url = this.$auth.user().provider ? 'providers' : 'user'
          this.axios.patch(`admin/${url}/${this.$auth.user().uuid}`, this.changePasswordDialog.form)
            .then(() => {
              this.$toast.success('Password updated')
              this.changePasswordDialog.open = false
              this.changePasswordDialog.form = {}
            })
            .catch(error => {
              if (error.response?.data?.message) {
                this.$toast.error(error.response.data.message)
              }
            })
            .finally(() => {
              this.changePasswordDialog.loading = false
            })
        }
      },
      replaceRol(role, on, index) {
        this.$auth.user().roles[role].splice(index, 1);
        this.$auth.user().roles[role].push(on);
      },
      hideData (role, on, off) {

        var index = this.$auth.user().roles[role].indexOf(on);
        if (index !== -1) {
          this.replaceRol(role, off, index)
          this.$set(this.$store.state.app.settings, `hide_${role}`, true)
          this.setSettings(this.$store.state.app.settings)
        } else {
          index = this.$auth.user().roles[role].indexOf(off);
          this.replaceRol(role, on, index)
          this.$delete(this.$store.state.app.settings, `hide_${role}`)
          this.setSettings(this.$store.state.app.settings)
        }
      },
    },
  }
</script>
